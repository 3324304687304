import { PlatformEvent, EditorSDK, EditorPlatformApp, InstallationOriginType } from '@wix/platform-editor-sdk';
import {
  withMembersArea,
  isMembersAreaInstalled,
  installRegisteredApps,
  registerMembersAreaApps,
  installMembersArea,
} from '@wix/members-area-integration-kit';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';
import { getOrganizationFullByAppDefAndInstance } from './core/oloApi';
import webBiLogger from '@wix/web-bi-logger';
import initSchemaLogger from '@wix/bi-logger-olo-client';
import uuid from 'uuid';
import { getOrganizationAndMenu, fetchRestaurant, Restaurant } from '@wix/restaurants-client-logic';
import SuccessIconSVG from './Illustrations/SuccessIcon.svg';
import { EditorScriptFlowAPI, Experiments } from '@wix/yoshi-flow-editor';
import _ from 'lodash';
import { getErrorAsString } from './core/logic/errorLogic';

const biLogger = initSchemaLogger(webBiLogger)();

let useLogger = true;

export function enableLogger() {
  useLogger = true;
}

export function disableLogger() {
  useLogger = false;
}

function log(...str: string[]) {
  if (useLogger) {
    console.log('[Restaurants editor script]', ...str);
  }
}

let appToken: string;
let experiments: Experiments;

const LOYALTY_APP_ID = '553c79f3-5625-4f38-b14b-ef7c0d1e87df';
export const ORDERS_APP_ID = '13e8d036-5516-6104-b456-c8466db39542';
export const IFRAME_WIDGET_ID = '13e8d047-31b9-9c1f-4f89-48ba9430f838';
export const OOI_WIDGET_ID = '96254d42-7323-40cb-a7cb-b7c242019728';
const membersAreaAppDefIds = [MA_APP_IDS.MY_ADDRESSES, MA_APP_IDS.MY_WALLET];
export const confirmationPanelHeader = 'OnlineOrdering_PublishModal_Header';
export const confirmationPanelBody = 'OnlineOrdering_PublishModal_Body';
export const confirmationPanelCTA = 'OnlineOrdering_PublishModal_CTA';
export const confirmationPanelIllustration = SuccessIconSVG;

export const EDITOR_BASEURL =
  typeof window !== 'undefined' ? _.get(window, 'location.hostname') || 'editor.wix.com' : 'editor.wix.com';

async function upgradeIframeToOoi(
  editorSDK: EditorSDK,
  iframeComponent: any,
  msid?: string,
  restaurantId?: string,
): Promise<boolean> {
  try {
    log('attempt to migrate to OOI');
    await editorSDK.document.transactions.runAndWaitForApproval('', () => {
      log('transaction started...');
      return editorSDK.document.components.data.update('', {
        componentRef: iframeComponent.componentRef,
        data: { widgetId: OOI_WIDGET_ID },
      });
    });
    log('successfully migrated to OOI');
    return true;
  } catch (e) {
    log('encountered an error!', getErrorAsString(e));
    fireBiWithGrace(() => {
      biLogger.dashboardAutopilotOloMigrationError({
        errorDescription: getErrorAsString(e),
        site_id: msid,
        organization_id: restaurantId,
      });
    });
    return false;
  }
}

// [TODO] - remove this when ooi migration is done - https://app.asana.com/0/1202258859998330/1202258859998311/f
const fireBiWithGrace = (callback: Function) => {
  try {
    callback();
  } catch (error) {
    // don't fail on bi
  }
};

let errorLoggerForOOIMigration: string;
let editorReadyDuration: number;

export const editorReadyImpl = async (
  editorSDK: EditorSDK,
  appDefinitionId: any,
  platformOptions: any,
  flowAPI: EditorScriptFlowAPI,
) => {
  try {
    const editorReadyStart = performance?.now();
    experiments = flowAPI.experiments;
    appToken = appDefinitionId;
    const { instanceId, applicationId, language, viewMode, metaSiteId, instance } = platformOptions.initialAppData;
    let restaurant: undefined | Restaurant;
    let isTemplate = false;
    try {
      restaurant = (
        await fetchRestaurant(instance, undefined, { retry: { retries: 3 }, host: `https://${EDITOR_BASEURL}` })
      )?.restaurant;
    } catch {
      restaurant = undefined;
    }

    if (!restaurant) {
      const organizationFull = await getOrganizationAndMenu(instance, undefined);
      isTemplate = !organizationFull || !organizationFull.fromSite;
      log('checking if the siteis still a template: ' + isTemplate);

      if (!organizationFull || !organizationFull.restaurant) {
        restaurant = (await getOrganizationFullByAppDefAndInstance(instanceId, applicationId, language)).restaurant;
      } else {
        restaurant = organizationFull.restaurant;
      }
    }

    if (!restaurant) {
      throw new Error('CANNOT FETCH ORGANIZATION!');
    }

    biLogger.util.updateDefaults({
      oloSessionId: uuid.v4(),
      restaurantId: restaurant.id,
      viewMode,
      msid: metaSiteId,
      instanceId,
      appId: applicationId,
    });

    log('Fetching website TPAs.');
    const tpas = await editorSDK.components.findAllByType('', {
      componentType: 'wysiwyg.viewer.components.tpapps.TPASection',
    });

    log('Fetching TPAs components data.');
    const tpasData = await Promise.all(
      tpas.map(async (componentRef) => ({
        componentRef,
        data: await editorSDK.components.data.get('token', { componentRef }),
      })),
    );

    log('Searching for online-orders iframe.');
    const iframeComponent = tpasData.find(
      (tpaData: any) =>
        tpaData?.data?.appDefinitionId === ORDERS_APP_ID && tpaData?.data?.widgetId === IFRAME_WIDGET_ID,
    ) as any;

    fireBiWithGrace(() => {
      biLogger.dashboardAutopilotOloLogMigration({
        log: JSON.stringify({
          hasIframe: !!iframeComponent?.componentRef,
        }),
        instance_id: instanceId,
        site_id: metaSiteId,
        organization_id: restaurant?.id,
      });
    });

    fireBiWithGrace(() => {
      biLogger.dashboardAutopilotOloMigrationStart({
        statusBeforeMigration: iframeComponent?.componentRef ? 'iframe' : 'ooi',
        instance_id: instanceId,
        site_id: metaSiteId,
        organization_id: restaurant?.id,
      });
    });

    if (iframeComponent && iframeComponent.componentRef) {
      log(`found iframe component! (id = "${iframeComponent.componentRef.id}")`);

      log('replacing data.widgetId to OOI...');

      try {
        const success = await upgradeIframeToOoi(editorSDK, iframeComponent, metaSiteId, restaurant.id);

        fireBiWithGrace(() => {
          biLogger.dashboardAutopilotOloMigrationEnd({
            isSuccess: success,
            instance_id: instanceId,
            site_id: metaSiteId,
            organization_id: restaurant?.id,
            statusAfterMigration: success ? 'ooi' : 'iframe',
          });
        });

        if (!success) {
          throw new Error('OOI migration was not successful - aborting');
        }
      } catch (error) {
        fireBiWithGrace(() => {
          biLogger.dashboardAutopilotOloMigrationError({
            instance_id: instanceId,
            site_id: metaSiteId,
            organization_id: restaurant?.id,
            errorDescription: (error as Error).message,
          });
        });
        throw error;
      } finally {
        editorReadyDuration = performance?.now() - editorReadyStart;
      }

      log('done!');
    } else {
      log('iframe component was not found. already migrated');
    }

    if (experiments.enabled('specs.restaurants.olo-client-members-area')) {
      log('Member area experiment is enabled, attempting to set settings...');
      //
      const shouldSkipInstallForSilentInstallation =
        platformOptions?.origin?.info?.type === InstallationOriginType.SILENT_INSTALL_SITE_CREATION &&
        experiments.enabled('specs.restaurants.skip-members-installation-silentMode');
      log(
        'shouldSkipInstallForSilentInstallation',
        String(shouldSkipInstallForSilentInstallation),
        String(platformOptions?.origin?.info?.type),
      );

      if (!shouldSkipInstallForSilentInstallation) {
        log('Member area skip adding for silent installation is disabled- Installing...');
        await installMembersArea();
      }

      const oloComponent = tpasData.find((tpaData: any) => tpaData?.data?.appDefinitionId === ORDERS_APP_ID) as any;

      if (oloComponent && oloComponent.componentRef) {
        log(`found OLO component! (id = "${oloComponent.componentRef.id}")`);

        const publicData = await editorSDK.document.tpa.data.getAll('token', { compRef: oloComponent.componentRef });

        log('is integrated to members area:', String(Boolean(publicData?.COMPONENT?.hasMembersAreaIntegration)));

        if (!shouldSkipInstallForSilentInstallation && !publicData?.COMPONENT?.hasMembersAreaIntegration) {
          try {
            log('setting hasMembersAreaIntegration value to true');
            await editorSDK.document.transactions.runAndWaitForApproval('', () => {
              return editorSDK.document.tpa.data.set('', {
                compRef: oloComponent.componentRef,
                scope: 'COMPONENT',
                key: 'hasMembersAreaIntegration',
                value: true,
              });
            });
            log('hasMembersAreaIntegration value was set to true successfully');

            if (await isMembersAreaInstalled()) {
              log('members area is already installed, adding required modules...');
              await registerMembersAreaApps(membersAreaAppDefIds);
              await installRegisteredApps();
              log('required modules added successfully');
            }
          } catch (e) {
            log('encountered an error while setting hasMembersAreaIntegration value!', getErrorAsString(e));
          }
        }
      } else {
        log('OLO component was not found!');
      }
    } else {
      log('Member area experiment is disabled.');
    }
  } catch (e) {
    errorLoggerForOOIMigration = getErrorAsString(e);
    throw e;
  }
};

const OPEN_ORDER_SETTING = 'open_order_setting';
const OPEN_MANAGE_MENUS = 'open_manage_menus';
const OPEN_ORDERS = 'open_orders';
const getAppManifestImpl = (options: any, editorSDK: EditorSDK, contextParams: any, flowAPI: EditorScriptFlowAPI) => {
  const appDescriptor = {
    mainActions: [],
    customActions: [
      {
        title: flowAPI.translations.t('online_ordering_editor_app_manager_quick_action_1'),
        actionId: OPEN_MANAGE_MENUS,
        icon: 'appManager_relatedAppsAction',
        type: 'dashboard',
      },
      {
        title: flowAPI.translations.t('online_ordering_editor_app_manager_quick_action_2'),
        actionId: OPEN_ORDER_SETTING,
        icon: 'appManager_settingsAction',
        type: 'dashboard',
      },
      {
        title: flowAPI.translations.t('online_ordering_editor_app_manager_quick_action_3'),
        actionId: OPEN_ORDERS,
        icon: 'appManager_pagesAction',
        type: 'dashboard',
      },
    ],
    defaultActions: {
      upgrade: {
        upgradeType: 'SITE_UPGRADE',
        upgradeText: flowAPI.translations.t('online_ordering_editor_app_manager_upgrade_text'),
        upgradeLinkText: flowAPI.translations.t('online_ordering_editor_app_manager_upgrade_link_text'),
      },
      learnMoreKB: 'a1cedc92-d3f6-4937-a0b5-4387abb463e0',
    },
  };
  return { appDescriptor };
};

interface HandlerParams<PayloadType = any> {
  sdk: EditorSDK;
  payload?: PayloadType;
  appToken: string;
}
interface AppActionPayload {
  actionId: string;
}

async function onEventImpl({ eventType, eventPayload }: PlatformEvent, editorSDK: EditorSDK) {
  switch (eventType) {
    case 'appActionClicked':
      const { actionId } = eventPayload;
      await appActionHandlers?.[actionId]?.({ sdk: editorSDK, payload: eventPayload, appToken });
      break;
    default:
      return;
  }
}

const appActionHandlers: { [key: string]: (params: HandlerParams<AppActionPayload>) => void } = {
  [OPEN_MANAGE_MENUS]: async ({ sdk, appToken: appDefId }) => {
    await sdk.editor.openDashboardPanel(appDefId, { closeOtherPanels: false, url: '/restaurants' });
    return sdk.tpa.app.refreshApp(appDefId);
  },
  [OPEN_ORDERS]: async ({ sdk, appToken: appDefinitionId }) => {
    await sdk.editor.openDashboardPanel(appDefinitionId, { closeOtherPanels: false, url: '/restaurants/orders' });
    return sdk.tpa.app.refreshApp(appDefinitionId);
  },
  [OPEN_ORDER_SETTING]: async ({ sdk, appToken: appDefinitionId }) => {
    await sdk.editor.openDashboardPanel(appDefinitionId, {
      closeOtherPanels: false,
      url: '/restaurants/ordersSettings',
    });
    return sdk.tpa.app.refreshApp(appDefinitionId);
  },
};

// TODO: remove this when ooi migration is done - https://app.asana.com/0/1202258859998330/1202258859998311/f
const getIframeComponent = async (editorSDK: EditorSDK) => {
  const tpas = await editorSDK.components.findAllByType('', {
    componentType: 'wysiwyg.viewer.components.tpapps.TPASection',
  });

  const tpasData = await Promise.all(
    tpas.map(async (componentRef) => ({
      componentRef,
      data: await editorSDK.components.data.get('token', { componentRef }),
    })),
  );

  return tpasData.find(
    (tpaData: any) => tpaData?.data?.appDefinitionId === ORDERS_APP_ID && tpaData?.data?.widgetId === IFRAME_WIDGET_ID,
  ) as any;
};

const handleActionImpl: EditorPlatformApp['handleAction'] = async ({ type, payload }, editorSDK) => {
  switch (type) {
    // TODO: remove this when ooi migration is done - https://app.asana.com/0/1202258859998330/1202258859998311/f
    case 'migrate': {
      if ((payload as { migrationType?: string })?.migrationType === 'ooi') {
        const editorReadyInfo = `editorReadyTime: ${editorReadyDuration}. errorLoggerForOOIMigration: ${
          errorLoggerForOOIMigration || 'no error'
        }`;
        try {
          const iframeComponent = await getIframeComponent(editorSDK);
          if (iframeComponent) {
            const isUpgraded = await upgradeIframeToOoi(editorSDK, iframeComponent);
            return `isUpgraded: ${isUpgraded}. ${editorReadyInfo}`;
          }
          return `isUpgraded: no - iframe component not found. ${editorReadyInfo}`;
        } catch (e) {
          return `isUpgraded: no - error: ${getErrorAsString(e)}. ${editorReadyInfo}`;
        }
      }
      return editorSDK.document.transactions.runAndWaitForApproval(appToken, () =>
        editorSDK.document.tpa.add.application(appToken, {
          appDefinitionId: LOYALTY_APP_ID,
        }),
      );
    }
    default:
      break;
  }
};

const platformApp = withMembersArea(
  {
    editorReady: editorReadyImpl,
    getAppManifest: getAppManifestImpl,
    onEvent: onEventImpl,
    handleAction: handleActionImpl,
  },
  {
    disableADI: false,
    installAutomatically: false,
    installAppsAutomatically: true,
    membersAreaApps: membersAreaAppDefIds,
    experimentOptions: {
      name: 'specs.restaurants.olo-client-members-area',
      scope: 'restaurants',
      truthyValue: 'true',
    },
  },
);

export const editorReady = platformApp.editorReady;
export const getAppManifest = platformApp.getAppManifest;
export const onEvent = platformApp.onEvent;
export const handleAction = platformApp.handleAction;
